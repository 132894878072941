<template>
    <div class="pressure-record-edit-parameter-bpap-s">
        <div class="pressure-record-edit-parameter-bpap-s-top">
            <div class="pressure-record-edit-parameter-bpap-s-column" style="margin-right: 25px;">
                <record-selector title="起始压力" v-model="startPressure" :dataArray="startPressureArray"></record-selector>
                <record-selector title="吸气压力" v-model="breathInPressure"
                    :dataArray="breathInPressureArray"></record-selector>
                <record-selector title="升压速度" v-model="upSpeed" :dataArray="upSpeedArray"></record-selector>
                <record-selector title="吸气灵敏度" v-model="breathInSenstive"
                    :dataArray="breathInSenstiveArray"></record-selector>
            </div>
            <div class="pressure-record-edit-parameter-bpap-s-column">
                <record-selector style="margin-top:64px" title="呼气压力" v-model="breathOutPressure"
                    :dataArray="breathOutPressureArray"></record-selector>
                <record-selector title="降压速度" v-model="downSpeed" :dataArray="downSpeedArray"></record-selector>
                <record-selector title="呼气灵敏度" v-model="breathOutSenstive"
                    :dataArray="breathOutSenstiveArray"></record-selector>
            </div>
        </div>
        <common-bottom ref="bottomRef" :cureMode="cureMode" :initParamModel="initParamModel"></common-bottom>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import RecordSwitch from "@c/record-frame/common/record-switch.vue";
import RecordRadio from "@c/record-frame/common/record-radio.vue";
import CommonBottom from "./bottom.vue";
import { pressureArray, commonArray } from "@js/parameter-option-data-source.js";
export default {

    components: {
        RecordSelector,
        RecordSwitch,
        RecordRadio,
        CommonBottom
    },

    props: {
        initParamModel: Object
    },

    data() {
        return {
            cureMode: CURE_MODE.BPAPS,
            paramModel: undefined,
            //选中项
            startPressure: undefined,
            breathInPressure: undefined,
            breathOutPressure: undefined,
            upSpeed: undefined,
            downSpeed: undefined,
            breathInSenstive: undefined,
            breathOutSenstive: undefined,
            //选项数组
            upSpeedArray: commonArray,
            downSpeedArray: commonArray,
            breathInSenstiveArray: commonArray,
            breathOutSenstiveArray: commonArray,
            //ref
            bottomRef: undefined
        }
    },

    computed: {
        //选项数组
        startPressureArray() {
            return pressureArray(4, this.breathOutPressure)
        },

        breathInPressureArray() {
            return pressureArray(this.breathOutPressure, 25)
        },

        breathOutPressureArray() {
            return pressureArray(this.startPressure, this.breathInPressure)
        },

        jsonStr() {
            return JSON.stringify({
                cureMode: this.cureMode,
                startPressure: this.startPressure * 10,
                bpapInP: this.breathInPressure * 10,
                bpapExP: this.breathOutPressure * 10,
                boostslope: this.upSpeed,
                buckslope: this.downSpeed,
                inhaleSensitive: this.breathInSenstive,
                exhaleSensitive: this.breathOutSenstive,
                cureDelay: this.$refs.bottomRef.delayPressure,
                humidifyLevel: this.$refs.bottomRef.wetLevel,
                intelligentStart: this.$refs.bottomRef.smartStart,
                intelligentStop: this.$refs.bottomRef.smartStop,
                leakAlarm: this.$refs.bottomRef.leakRemindTime,
                maskTypeId: this.$refs.bottomRef.maskType,
                mask: this.$refs.bottomRef.maskCategory,
                pipe: this.$refs.bottomRef.tubeType,
                heattubeTem: this.$refs.bottomRef.tubeType == 0 ? 0 : this.$refs.bottomRef.heatTubeTemperature
            })
        }

    },

    async created() {
        this.paramModel = { ...this.initParamModel }
        if (this.paramModel.cureMode == this.cureMode) {
            this.startPressure = parseFloat(this.paramModel.startPressure / 10)
            this.breathInPressure = parseFloat(this.paramModel.bpapInP / 10)
            this.breathOutPressure = parseFloat(this.paramModel.bpapExP / 10)
            this.upSpeed = parseInt(this.paramModel.boostslope)
            this.downSpeed = parseInt(this.paramModel.buckslope)
            this.breathInSenstive = parseInt(this.paramModel.inhaleSensitive)
            this.breathOutSenstive = parseInt(this.paramModel.exhaleSensitive)
        } else {
            this.paramModel.cureMode = this.cureMode
            this.startPressure = 4
            this.breathInPressure = 8
            this.breathOutPressure = 6
            this.upSpeed = 3
            this.downSpeed = 3
            this.breathInSenstive = 2
            this.breathOutSenstive = 2
        }

    },

    mounted() {
        this.bottomRef = this.$refs.bottomRef
    }
}
</script>

<style lang="scss" scoped>
.pressure-record-edit-parameter-bpap-s {
    &-top {
        display: flex;
    }

    &-column {
        width: 50%;
    }
}
</style>